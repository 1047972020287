import _ from 'lodash';
import module from 'module';

const templateUrl = require('./new-appraisal.template.html');
module.component('newAppraisal', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '=',
    pawnTypeId: '=',
    pawnItemId: '='
  },
  controller: function (http, notification, command, nav, customerCache, newPawnService, authentication) {
    this.saveItem = async (item) => {
      const request = {
        type: 'PAWNED',
        migrated: item.migrated,
        requestAmount: item.requestAmount,
        categoryId: item.category.id,
        classClassificationId: (item.classClassification && item.classClassification.id) || null,
        typeId: item.type.id,
        subtypeId: item.subtype ? item.subtype.id : null,
        customType: item.customType,
        quantity: item.quantity,
        defectIds: item.defectIds,
        valuation: item.valuation,
        overloadedValuation: item.overloadedValuation,
        deprecatedProductNumber: item.deprecatedProductNumber,
        remarks: item.remarks,
        attributes: item.attributes,
        plain: !item.withStones,
        weight: item.weight,
        stones: item.stones ? _.union(item.stones.valuable, item.stones.nonValuable) : null,
        origin: item.origin,
        metal: item.metal ? item.metal : null,
        autoDescription: item.autoDescription,
        inStock: false,
        status: 'APPRAISED'
      };

      await getOrCreateOverriddenMetalRate(request.metal);

      // Add pawn item attributes
      if (request.attributes && request.attributes.length > 0) {
        _.forEach(request.attributes, (attribute) => {
          Object.assign(attribute, {attributeTypeId: attribute.type.id});
        })
      }
      // Add pawn item stones
      if (request.stones && request.stones.length > 0) {
        _.forEach(request.stones, (stone) => {
          Object.assign(stone, {stoneTypeId: stone.type.id});
          Object.assign(stone, {stoneColourId: stone.stoneColour ? stone.stoneColour.id : null});
          Object.assign(stone, {stoneRateId: stone.stoneRate ? stone.stoneRate.id : null});
          Object.assign(stone, {stoneCutGradeId: stone.stoneCutGrade ? stone.stoneCutGrade.id : null});
        })
      }

      const back = () => {
        // If [id] is given item is edited and we have to go back by 2 steps
        // Otherwise item is created and only 1 step is required
        nav.back(item.id ? 2 : 1);
      };

      const onSuccess = (itemId) => {
        notification.show("Success", "Pawn item persisted");
        const customerId = customerCache.loadedCustomerId;
        request.id = itemId;
        request.branchId = authentication.context.branchId;
        newPawnService.removeAppraisal(request);
        newPawnService.addAppraisal(request, customerId);
        back();
      };

      const onError = () => notification.show("Error", "Failed to persist pawn item");

      // Set uploaded file ids
      request.fileIds = _.map(item.files, f => f.id);
      // If item id is given update it, otherwise create it
      if (item.id > 0) {
        request.itemId = item.id;
        command.execute('EditPawnItem',
          request,
          {nxLoaderText: 'Updating pawn item'})
          .success((res) => onSuccess(res.output.id))

      } else {
        http.post(`/products/pawns/items`, {...request, type: 'PAWNED'}, {nxLoaderText: 'Creating pawn item'})
          .success((id) => onSuccess(id))
          .error(() => onError())
      }
    };

    if (!customerCache.loadedCustomerId) {
      customerCache.pawns(this.customerId);
    }
    this.pawnItem = _.find(newPawnService.getAppraisals(), {id: Number(this.pawnItemId)});

    const getOrCreateOverriddenMetalRate = async (metal) => {
      // Create or fetch an existing pawn metal rate with the same fineness and price per gram.
      if (metal && metal.rate && metal.rate.overridden) {
        const metalRate = metal.rate;
        const pawnMetalRateRequest = {
          systemDate: metalRate.systemDate,
          name: metalRate.name,
          metalTypeIds: metalRate.metalTypeIds,
          fineness: metalRate.fineness,
          pricePerGram: metalRate.pricePerGram,
          orderNo: 0,
          overridden: true,
          type: 'PAWNED'
        };
    
        const overriddenMetalRate = await http.post(`/products/pawns/get-or-create-overridden-metal-rate`, pawnMetalRateRequest, {nxLoaderText: 'Updating metal prices'}).toPromise();
        metal.metalRateId = overriddenMetalRate.id;
      }
    }
  }
});
