import "rxjs/add/observable/fromPromise";
import "rxjs/add/observable/of";
import "rxjs/add/operator/combineAll";
import {Subscription} from "rxjs/Subscription";
import _ from "lodash";
import $ from 'jquery';

import module from "module";

export const isReleasedOrClosed = (pawn) => pawn && ['ACTIVE', 'MATURE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING', 'AUCTIONED', 'STOCKED', 'PAST_DUE_WRITE_OFF', 'CLOSED'].includes(pawn.status);

const templateUrl = require('./customer-pawns.template.html');
module.component('customerPawns', {
  templateUrl: templateUrl,
  controller: function ($route, $routeParams, $location, $scope, http, customerCache, confirmation, notification,
                        userCache, nav, pawnStatusMapper, pawnProductsCache, $filter, pawnItemTypeCache, pawnMetalRateCache,
                        branchService, authentication, branchWorkingDayCache, dashboardActionService, confirmationTemplate,
                        command, queryParamsRemover, customerService, pawnTagCache, pawnItemCache, popup) {
    let that = this;

    $scope.permission = authentication.permissions;

    const closedPawnStatuses = ['CLOSED', 'CANCELED'];
    that.hideClosed = $routeParams.hideClosed === "true";
    that.visiblePawns = [];

    that.pawnStatusMapper = pawnStatusMapper;
    that.workingDay = null;
    that.contextBranchId = authentication.context.branchId;
    that.pawns = [];

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    queryParamsRemover.removeQueryOnRedirect($scope, ['hideClosed']);

    that.togglePawns = () => {
      if (that.hideClosed) {
        that.visiblePawns = that.pawns.filter(pawn => pawn.status !== 'CLOSED');
      } else {
        that.visiblePawns = that.pawns;
      }

      if (that.selectedPawn) {
        $location
          .path(`/customer/${customerId}/pawns/${that.selectedPawn.id}`)
          .search('hideClosed', that.hideClosed.toString());
      }
    };

    const isPawnClosed = (pawnId) => {
      const pawn = _.find(that.pawns, {id: pawnId});
      return pawn && _.includes(closedPawnStatuses, pawn.status);
    };

    const isWithdrawnAndPermittedToSee = (pawn) => pawn.status === 'PAST_DUE_WRITE_OFF' && authentication.permissions['MNG_PWN_WITHDRAWN_READ'];

    // This method is called from 2 places due to how 'tags' and 'selectedPawn' are resolved
    // Either there are no 'tags' once 'selectedPawn' is initialized or the other way, needs nice refactor
    const assignTagName = (pawn, tags) => {
      if (!_.isEmpty(pawn)) {
        pawn.tag = _.first(_.filter(tags, t => t.id === pawn.tagId).map(t => t.name));
      }
    };

    const s = customerCache.pawns(customerId).toObservable()
      .combineLatest(pawnProductsCache.toObservable(), (pawns, pawnTypes) =>
        pawns.map(pawn => {
          // add pawnProduct to pawn object
          const type = _.find(pawnTypes, {id: pawn.typeId});
          return Object.assign(pawn, {
            pawnType: type
          });
        })
      )
      .combineLatest(branchService.toObservable(), (pawns, branches) =>
        pawns.map(pawn => Object.assign(pawn, {branch: _.find(branches, {id: pawn.branchId})}))
      )
      .combineLatest(userCache.toObservable(), (pawns, users) =>
        pawns.map(pawn => Object.assign(pawn, {
          user: _.find(users, {id: pawn.userId}),
          appraisalUser: _.find(users, {id: pawn.appraisalUserId})
        }))
      )
      .subscribe(allPawns => {

          // Select only pawns available for users
          const branchIds = authentication.context.branchIds;
          const pawns = _.filter(allPawns, p => _.includes(branchIds, p.branchId) || isWithdrawnAndPermittedToSee(p));

          // select first account when none selected
          if (!pawnId && pawns.length > 0) $location.path(`/customer/${customerId}/pawns/${pawns[0].id}`);

          if (pawnId) {
            const pawn = _.find(pawns, (a) => String(a.id) === pawnId);
            if (pawn) {
              that.selectedPawn = pawn;
            } else {
              $location.path(`/customer/${customerId}/pawns`);
            }
          }

          that.pawns = pawns.map(p => {
            // Calculate total outstanding
            p.totalOutstandingBalance = p.principalBalance + p.interestBalance + p.pastDueInterestBalance + p.penaltyBalance;
            // Find parent pawn (only if parent pawn is owned by the same user)
            if (p.parentPawnId) {
              const parentPawn = _.find(pawns, {id: p.parentPawnId});
              p.parentPawnNumber = parentPawn ? parentPawn.productNumber : 'N/A';
            }
            // Find root pawn (only if root pawn is owned by the same user)
            if (p.rootPawnId) {
              const rootPawn = _.find(pawns, {id: p.rootPawnId});
              p.rootPawnNumber = rootPawn ? rootPawn.productNumber : 'N/A';
            }

            return p;
          });

          if (that.hideClosed) {
            that.visiblePawns = that.pawns.filter(pawn => pawn.status !== 'CLOSED');

            if (that.selectedPawn && that.selectedPawn.status === 'CLOSED' && that.selectPawn && that.visiblePawns[0]) {
              that.selectPawn(that.visiblePawns[0]);
            }
          } else {
            that.visiblePawns = that.pawns;
          }

          assignTagName(that.selectedPawn, that.tags);
        }
      );

    let s2 = pawnItemTypeCache.toObservable().subscribe(types => {
      that.typeMap = {};
      for (let t of types) {
        that.typeMap[t.id] = t.name;
      }
    });

    let s3 = pawnMetalRateCache.toObservable().subscribe(metals => {
      that.metalMap = {};
      for (let m of metals) {
        that.metalMap[m.id] = m.fineness;
      }
    });

    let s4 = branchWorkingDayCache.withParam(that.contextBranchId).toObservable().subscribe(workingDay => {
      that.workingDay = workingDay;
    });

    that.mapDefectLevel = (defectLevel) => {
      if (defectLevel === 'NONE') return 'Good';
      return $filter('prettyEnum')(defectLevel);
    };

    const subscription = new Subscription();
    subscription.add(s);
    subscription.add(s2);
    subscription.add(s3);
    subscription.add(s4);

    that.transactionClicked = (transaction, $event) => {
      $event.stopPropagation();
      that.selectedTransaction = transaction.id;
    };

    that.hideInlinePanel = () => {
      that.selectedTransaction = null;
    };

    that.selectpawn = (pawn) => {
      that.pawnApprovalTask = null;
      that.selectedPawn = pawn;
      $location.path(`/customer/${customerId}/pawns/${pawn.id}`);
    };

    that.numberOfPawnedItems = (pawn) => pawn ? _.filter(pawn.items, i => (i.status == 'PAWNED_ACTIVE' || i.status == 'PAWNED_EXPIRED')).length : 0;

    that.$onInit = async () => {
      const profile = await customerCache.profile(customerId).toPromise();
      await customerService.redirectWhenProfileIsInvalid(profile);
      that.tags = await pawnTagCache.toPromise();
      assignTagName(that.selectedPawn, that.tags);
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };

    that.createNew = () => nav.setSource('customer-pawns') && $location.path(`/customer/${customerId}/pawns/create`);
    that.withdrawForAuction = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/withdraw`);
    that.releaseByCash = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/release/cash`);
    that.redeem = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/redeem`);
    that.renew = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/renew`);
    that.renewWithExtraCash = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/loan-addition`);
    that.partialRenewal = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/renew-partial`);
    that.changeOwner = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/change-owner`);
    that.partialRedemption = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/redemption-partial`);
    that.ptPreview = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/ticket`);
    that.updatePawn = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/update`);
    that.updatePawnItems = () => $location.path(`/customer/${customerId}/pawns/${that.selectedPawn.id}/update-items`);

    that.sendForApproval = () => confirmation('Do you want to send the pawn for approval?', () =>
      http.post(`/products/pawns/${that.selectedPawn.id}/send-for-approval`).success(() => {
        notification.show("Pawn has been sent for approval");
        customerCache.pawns(customerId).refetch();
        $route.reload();
      }).error((data) => {
        showError(data, 'Send for approval');
      })
    );

    that.approve = ($event) => {
      if ($event) $event.stopPropagation();
      // Refetch task associated with given pawn
      const pawnId = that.selectedPawn.id;
      http.get(`/tasks?taskGroup=PAWN_APPROVAL&objectId=${pawnId}`).success((task) => {
        // If task associated with pawn is not PENDING -> refetch pawns
        if (task.approvalStatus === 'PENDING') {
          that.pawnApprovalTask = task;
        } else {
          customerCache.pawns(customerId).refetch();
          $route.reload();
        }
      }).error((data) => {
        showError(data, 'Approve');
      })
    };

    that.approvalPinEntered = (task, pin, user) => dashboardActionService.pinEntered(task, pin, user, (task) => {
      customerCache.pawns(customerId).refetch();
      $route.reload();
    });

    that.cancelPawn = () => confirmation('Do you want to cancel the pawn?', () =>
      http.doDelete(`/products/pawns/${that.selectedPawn.id}/cancel`).success(() => {
        notification.show("Pawn has been canceled");
        customerCache.pawns(customerId).refetch();
        $route.reload();
      }).error((data) => {
        showError(data, 'Cancel');
      })
    );

    function showError(data, actionName) {
      const details = data.errorMessage ? data.errorMessage : "An unknown error occurred.";
      let message = `<strong>Error executing:&nbsp;</strong>'${actionName}'.`;

      if (details) message += `<br><br><strong>Details:&nbsp;</strong>${details}`;
      popup({text: message, renderHtml: true});
    }

    that.reopenPawn = () => {
      confirmationTemplate({
        question: `Do you want to reopen the pawn ${that.selectedPawn.productNumber}?`,
        details: [
          {label: 'Principal amount', description: $filter('php')(that.selectedPawn.principalAmount)},
          {label: 'Interest amount', description: $filter('php')(that.selectedPawn.interestAmount)},
          {label: 'Past due interest amount', description: $filter('php')(that.selectedPawn.pastDueInterestAmount)},
          {label: 'Penalty amount', description: $filter('php')(that.selectedPawn.penaltyAmount)},
          {label: 'Release amount', description: $filter('php')(that.selectedPawn.releaseAmount)},
          {label: 'Maturity date', description: $filter('prettyDate')(that.selectedPawn.maturityDate)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the pawn you selected is correct.',
        yesCallback: () => command.execute('ReopenRedeemedPawn', {
          productId: that.selectedPawn.id
        }).success(() => {
          notification.show("Pawn has been reopened");
          customerCache.pawns(customerId).refetch();
          $route.reload();
        })
      });
    };

    that.revertWithdrawPawn = async (item) => {
      const confirmed = await confirmation('Do you want to revert withdrawal of this pawn?');
      if (!confirmed) {
        return;
      }

      await command.execute("RollbackWithdrawPawn", {productId : item.id}).toPromise();
      customerCache.pawns(customerId).refetch();
      pawnItemCache.evict();
      $route.reload();
    }

    that.deletePawn = () => {
      let parent = that.selectedPawn.parentPawnId
        ? `<br>The following pawn will be reopened: <strong>${that.selectedPawn.parentPawnNumber}</strong>`
        : '';

      confirmation(`
      Do you want to delete the pawn?
      ${parent}
      <br><br><strong class="red">WARNING: Please be advised that this operation is irreversible.</strong>
      `, () => command.execute('DeletePawn', {
          productId: that.selectedPawn.id
        }).success(() => {
          notification.show("Pawn has been deleted");
          customerCache.pawns(customerId).refetch();
          $route.reload();
        }), () => {
        }, true
      );
    };

    that.isReleasedOrClosed = (pawn) => isReleasedOrClosed(pawn);

    that.isReleasedAndNotClosed = (pawn) => pawn && (pawn.status === 'ACTIVE' || pawn.status === 'MATURE' || pawn.status === 'PAST_DUE_PERFORMING' || pawn.status === 'PAST_DUE_NON_PERFORMING');

    that.isReopenAvailable = (pawn) => pawn && pawn.status && pawn.status === 'CLOSED';

    that.isRedemptionAvailable = (pawn) => that.isReleasedAndNotClosed(pawn) || (pawn && pawn.status === 'PAST_DUE_WRITE_OFF');

    that.isDeleteAvailable = (pawn) => pawn && pawn.status && !_.includes(['CLOSED', 'CANCELED'], pawn.status);

    that.isRevertWithdrawAvailable = (pawn) => pawn && pawn.status && pawn.status === 'PAST_DUE_WRITE_OFF';

    that.isSelectedPawnAuctioned = (pawn) => pawn && pawn.status && pawn.status === 'AUCTIONED';

    that.gotoPawn = (pawnId) => {

      // Check if pawn is hidden
      if (isPawnClosed(pawnId)) {
        $location.path(`/customer/${customerId}/pawns/${pawnId}`).search({displayClosed: 1});
        return;
      }

      // scroll to pawn
      const pawnRow = $(`#pawn_${pawnId}`);
      if (!pawnRow) {
        console.error('No pawn tag defined for pawnId: ', pawnId);
      } else {
        if (!isScrolledIntoView(pawnRow)) {
          const pawnYCoordinate = pawnRow.offset().top;
          $("html, body").animate({scrollTop: pawnYCoordinate - 20}, "fast");
        }
      }

      // change pawn context
      $location.path(`/customer/${customerId}/pawns/${pawnId}`);
    };

    // https://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
    function isScrolledIntoView(el) {
      let element = el[0];
      var elemTop = element.getBoundingClientRect().top;
      var elemBottom = element.getBoundingClientRect().bottom;

      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }

    that.isSelectedPawnRegisteredBranch = (pawn) => {
      return pawn && this.contextBranchId === pawn.branchId;
    }
  }
});
