import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import {addAccountLabels} from '../../../general-ledger/common/gl.utils';

import './stock-item-reserve-view.style.less';

const templateUrl = require('./stock-item-reserve-view.template.html');

class StockItemReserveView {
  constructor ($routeParams, $location, pawnItemCache, pawnItemTypeCache, authentication, notification, command,
               confirmationTemplate, actionCategoryCache, systemPropertyCache, glMappingsService, branchService,
               $filter, customerCache, confirmation) {
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.pawnItemCache = pawnItemCache;
    this.pawnItemTypeCache = pawnItemTypeCache;
    this.authentication = authentication;
    this.notification = notification;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.actionCategoryCache = actionCategoryCache;
    this.systemPropertyCache = systemPropertyCache;
    this.glMappingsService = glMappingsService;
    this.branchService = branchService;
    this.$filter = $filter;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
  }

  async $onInit() {
    this.item = {};

    this.amount = 0;
    this.discountRate = 0;
    this.discount = 0;

    this.discountRequired = true;
    this.discountRateRequired = true;

    const itemId = parseInt(this.$routeParams.itemId);
    this.request = {
      itemId: itemId,
      finalPrice: null,
      denominationBundle: null,
      remarks: null,
      customerId: null
    };

    const [properties, branches, categories, ledgerAccounts] = await Promise.all([
      this.systemPropertyCache.toPromise(),
      this.branchService.toPromise(),
      this.actionCategoryCache.toPromise(),
      this.glMappingsService.accounts.toPromise()
    ]);

    const ledgerSupportProperty = _.find(properties, {code: 'LEDGER_SUPPORT'});
    this.supportsLedger = ledgerSupportProperty && ledgerSupportProperty.value === 'TRUE';

    // Apply labels to accounts applicable for
    this.ledgerAccounts = addAccountLabels(ledgerAccounts);

    if (itemId) {
      this.pawnItemTypes = await this.pawnItemTypeCache.toPromise();
      const pawnItem = await this.pawnItemCache.withParam(itemId).toPromise();
      const branch = branches.find(b => b.id === pawnItem.branchId);

      this.item = {
        ...pawnItem,
        categoryLabel: this.getTypeLabel(pawnItem.categoryId),
        typeLabel: this.getTypeLabel(pawnItem.typeId),
        subtypeLabel: this.getTypeLabel(pawnItem.subtypeId)
      };

      if (this.item.reservation) {
        this.reservation = {
          ...this.item.reservation,
          reservedOn: moment(this.item.reservation.reservedOn).format('YYYY-MM-DD'),
          expirationDate: moment(this.item.reservation.expirationDate).format('YYYY-MM-DD'),
          forfeitedDate: moment(this.item.reservation.forfeitedDate).format('YYYY-MM-DD')
        }
      } else {
        this.reservation = {
          reservedOn: moment(branch.postingDate).format('YYYY-MM-DD'),
          expirationDate: moment(branch.postingDate).add(7, 'days').format('YYYY-MM-DD'),
          forfeitedDate: moment(branch.postingDate).add(14, 'days').format('YYYY-MM-DD')
        }
      }

      if (this.reservation && this.reservation.customerId) {
        const [customer, branches] = await Promise.all([
          this.customerCache.profile(this.reservation.customerId).toPromise(),
          this.branchService.toPromise()
        ]);

        this.customer = {
          ...customer,
          name: customer.effectiveName,
          birthDate: customer.individualData.birthDate,
          branch: branches.find(b => b.id === customer.branchId)
        };

        this.customer.customerId = this.customer.id
      }
    }

    this.categories = categories.filter(cat => cat.actionType === 'CASH_IN' && cat.code === 'SELL_PAWN_ITEM');
    // Select the first and only category
    this.transaction = {
      categoryId: this.categories[0].id
    }
    this.onCategoryChange();
  }

  setCustomer(customer) {
    this.customer = customer;
  }

  getTypeLabel(typeId) {
    const type = _.find(this.pawnItemTypes, {id: typeId});
    return type && type.name ? type.name : '-';
  }

  onReservationFeeChange() {
    this.onCategoryChange();
  }

  onCategoryChange() {
    this.remarksRequired = false;
    if (this.transaction.categoryId) {
      const category = _.find(this.categories, {id: this.transaction.categoryId});
      this.remarksRequired = category && category.remarksRequired;

      if (!this.supportsLedger) {
        // no need to fill units
        return;
      }

      if (category.ledgerAccountFullCode) {
        const fixedAccount = _.find(this.ledgerAccounts, {fullCode: category.ledgerAccountFullCode});

        this.blockedUnits = [
          {
            accountCode: fixedAccount.fullCode,
            amount: this.amount,
            entryType: this.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT'
          }
        ];
      } else {
        this.blockedUnits = [];
      }
    }
  };

  onDiscountRateChange() {
    this.discountDisabled = !!this.discountRate;
    this.discountRequired = !this.discountRate;
    this.discountRateRequired = !!this.discountRate;

    if (this.discountRate < 0) {
      this.discountRate = 0;
    }

    const currentValue = new BigNumber(this.item.sellingPrice);

    const discountPercentage = new BigNumber(parseFloat(this.discountRate)).div(100);
    const discountVal = currentValue.mul(discountPercentage);
    this.discount = discountVal.toNumber();

    this.amount = currentValue.minus(discountVal)
      .minus(new BigNumber(this.reservation.reservationFee))
      .toNumber();

    this.onCategoryChange();
  }

  onDiscountChange() {
    this.discountRateDisabled = !!this.discount;
    this.discountRateRequired = !this.discount;
    this.discountRequired = !!this.discount;
    const currentValue = new BigNumber(this.item.sellingPrice);
    const discountVal = new BigNumber(this.discount);

    const discountPercentage = discountVal.div(currentValue).mul(100).toNumber();
    // most suitable way to round to 2 decimal places
    this.discountRate = Math.round(discountPercentage * 100) / 100;

    this.amount = currentValue.minus(discountVal)
      .minus(new BigNumber(this.reservation.reservationFee))
      .toNumber();

    this.onCategoryChange();
  }

  goBack() {
    if (this.customer) {
      this.$location.path(`/customer/${this.customer.customerId}/stocked-items`);
    } else {
      this.$location.path('/dashboard/stocked-items');
    }
  }

  async cancel() {
    const confirmed = await this.confirmation('Do you want to cancel this reservation?');
    if (!confirmed) {
      return;
    }

    const request = {
      itemId: this.reservation.itemId,
      customerId: this.reservation.customerId,
      branchId: this.reservation.branchId,
      reservationId: this.reservation.id
    };

    const response = await this.command.execute('CancelReservedStockItem', request);
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    this.goBack();
  }

  async reserve() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to reserve the following item?`,
      details: [
        {label: 'Customer', description: `${this.customer.name} (${this.customer.customerNumber})`},
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Category', description: this.item.categoryLabel},
        {label: 'Type', description: this.item.typeLabel},
        {label: 'Subtype', description: this.item.subtypeLabel},
        {label: 'Reserved on', description: this.$filter('prettyDate')(this.reservation.reservedOn)},
        {label: 'Expiration Date', description: this.$filter('prettyDate')(this.reservation.expirationDate)},
        {label: 'Forfeited Date', description: this.$filter('prettyDate')(this.reservation.forfeitedDate)},
        {label: 'Reservation fee', description: this.$filter('php')(this.amount)},
        {label: 'Remarks', description: this.request.remarks || ''}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      itemId: this.item.id,
      customerId: this.customer.customerId,
      branchId: this.item.branchId,
      reservationFee: this.amount,
      units: this.supportsLedger ? this.transaction.units : null
    };
    
    await this.command.execute('ReserveStockItem', request).toPromise()
      .then(response => {
        if (response.approvalRequired) {
          return;
        }
        
        this.pawnItemCache.evict();
        this.$location.path(`/customer/${this.customer.customerId}/stocked-items/`);
      }
    );    
  }

  async claim() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to claim the following item?`,
      details: [
        {label: 'Customer', description: `${this.customer.name} (${this.customer.customerNumber})`},
        {label: 'Transaction category', description: this.categories.find(c => c.id === this.transaction.categoryId).name},
        {label: 'Category', description: this.item.categoryLabel},
        {label: 'Type', description: this.item.typeLabel},
        {label: 'Subtype', description: this.item.subtypeLabel},
        {label: 'Selling price', description: this.$filter('php')(this.item.sellingPrice)},
        {label: 'Discount rate', description: this.discountRate},
        {label: 'Discount', description: this.$filter('php')(this.discount)},
        {label: 'Final payment', description: this.$filter('php')(this.amount)},
        {label: 'Remarks', description: this.request.remarks || ''}
      ]
    });

    if (!confirmed) {
      return;
    }

    const request = {
      ...this.request,
      itemId: this.reservation.itemId,
      customerId: this.reservation.customerId,
      branchId: this.reservation.branchId,
      reservationId: this.reservation.id,
      finalPrice: this.amount,
      units: this.supportsLedger ? this.transaction.units : null
    };

    const response = await this.command.execute('ClaimReservedStockItem', request);
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict()
    this.goBack();
  }
}

module.component('stockItemReservationView', {
  templateUrl: templateUrl,
  controller: StockItemReserveView
});
